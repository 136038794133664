import {InsuranceList} from "./InsuranceList";
import {InsuranceCreate} from "./InsuranceCreate";
import {InsuranceEdit} from "./InsuranceEdit";

const resources = {
    list:InsuranceList,
    create:InsuranceCreate,
    edit: InsuranceEdit
}

export default resources;