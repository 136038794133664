import * as React from 'react';
import {MenuItemLink, usePermissions} from 'react-admin';
import PeopleIcon from '@material-ui/icons/People';
import DescriptionIcon from '@material-ui/icons/Description';
import DomainIcon from '@material-ui/icons/Domain';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import {makeStyles} from "@material-ui/core";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { ApplicationRoles } from '../app-constants';
import FolderIcon from '@material-ui/icons/Folder';
import CategoryIcon from '@material-ui/icons/Category';

const useMenuStyles = makeStyles((theme) => ({
    menu: {
        paddingTop: theme.spacing(2)
    }
}));

export const AnkamProMenu: React.FC = (props) => {
    const classes = useMenuStyles();
    const {loading, permissions} = usePermissions();

    if (loading) {
        return null;
    }
    
    return (
        <div className={classes.menu}>
            {
                (!permissions.includes(ApplicationRoles.Expert)) && (
                    <MenuItemLink to="/accidents" primaryText="Kazalar" leftIcon={<DescriptionIcon/>}/>
                )
            }
            {
                (permissions.includes(ApplicationRoles.SuperAdmin) || permissions.includes(ApplicationRoles.User)) &&
                (
                    <MenuItemLink to="/accident-user" primaryText="Kaza Sorumlusu Ara" leftIcon={<FindInPageIcon/>}/>
                )
            }
            {
                (permissions.includes(ApplicationRoles.SuperAdmin) || permissions.includes(ApplicationRoles.Expert)) &&
                (
                    <MenuItemLink to="/accidents-waiting-expert" primaryText="Kazalar(Exper)" leftIcon={<FindInPageIcon/>}/>
                )
            }
            {
                (permissions.includes(ApplicationRoles.Admin) || permissions.includes(ApplicationRoles.SuperAdmin) || permissions.includes(ApplicationRoles.Technical)) && (
                    <MenuItemLink to="/accidents-waiting-cover" primaryText="Kazalar(Kapak Bekleyen)" leftIcon={<DescriptionIcon/>}/>
                )
            }
            {
                (permissions.includes(ApplicationRoles.Admin) || permissions.includes(ApplicationRoles.SuperAdmin)) &&
                (
                    <>
                        <MenuItemLink to="/users" primaryText="Kullanıcılar" leftIcon={<PeopleIcon/>}/>
                        <MenuItemLink to="/insurances" primaryText="Sigorta Şirketleri" leftIcon={<DomainIcon/>}/>
                        <MenuItemLink to="/file-types" primaryText="Dosya Tipleri" leftIcon={<FolderIcon/>}/>
                        <MenuItemLink to="/accident-codes" primaryText="Kaza Kodları" leftIcon={<CategoryIcon />}/>
                    </>
                )
            }
            {
                (permissions.includes(ApplicationRoles.SuperAdmin)) &&
                (
                    <MenuItemLink to="/sessions" primaryText="Kullanıcı Giriş Geçmişi" leftIcon={<LockOpenIcon/>}/>
                )
            }
        </div>
    )
};