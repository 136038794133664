import exportFromJSON from 'export-from-json'

export const excelExporter = data => {
    // const postsForExport = data.map(post => {
    //     const { backlinks, author, ...postForExport } = post; // omit backlinks and author
    //     postForExport.author_name = post.author.name; // add a field
    //     return postForExport;
    // });

    const fileName = 'download'
    const exportType =  exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
    // exportFromJSON({data, 'Download', exportFromJSON.types.excel})
    // jsonExport(postsForExport, {
    //     headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
    // }, (err, csv) => {
    //     downloadCSV(csv, 'posts'); // download as 'posts.csv` file
    // });
};