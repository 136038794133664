import {FileTypeList} from "./FileTypeList";
import {FileTypeCreate} from "./FileTypeCreate";
import {FileTypeEdit} from "./FileTypeEdit";

const resources = {
    list:FileTypeList,
    create:FileTypeCreate,
    edit: FileTypeEdit
}

export default resources;