import { createMuiTheme } from '@material-ui/core';


export const ankamProTheme = createMuiTheme({
   palette:{
       primary:{
           main:"#4b31cc"
       },
       secondary:{
           main:"#4b31cc"
       },
   },
    overrides:{
       MuiButton:{
           textPrimary:{
               color:"#4b31cc"
           }
       }
    }
});