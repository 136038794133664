import {FC} from "react";
import {
    CreateProps,
    Create,
    TextInput,
    SelectInput, FileInput, FileField,
    TabbedForm,
    FormTab, required,
    ReferenceInput, AutocompleteInput,
    SaveButton, DeleteButton, Toolbar, useNotify,
    ArrayInput, SimpleFormIterator, number, minLength, maxLength, RadioButtonGroupInput, DateInput
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';
import {Typography, Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {insuranceNameCodeRenderer} from "../insurance/InsuranceList";
import {createAxios} from "../providers/dataprovider";
import {apiUrl, getAccidentTypeList} from "../app-constants";

const useStyles = makeStyles({
    inline_block: {display: 'inline-block'},
    inline_block_margin: {display: 'inline-block', marginLeft: 32},
    toolbar_style: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});

export const checkAccidentIsAddedAlready = async (values) => {
    const http = createAxios();
    const errors:any = { };
    
    const params = {
        ntfNumber: values.notificationNumber,
        accidentType: values.accidentType,
        responsibleInsuranceType: values.responsibleInsuranceType
    }
    const {data} = await http.get(`${apiUrl}/accidents/control`, { params });
    if (!data.success) {
        errors.notificationNumber = data.message;
        errors.accidentType = data.message;
        errors.responsibleInsuranceType = data.message;
    }
    return errors;
}

// const validateNtfNumber = [required(), checkAccidentIsAddedAlready];
const validateTcNumber = [number("Tc numarası sayılardan oluşmalıdır!"), minLength(11, "Lütfen 11 haneli numara giriniz!"), maxLength(11, "Lütfen 11 haneli numara giriniz!")];


export const AccidentCreateToolbar = props => {
    const notify = useNotify();
    const classes = useStyles();
    const onFailure = (error) => {
        const {response: {data: {message}}} = error;
        notify(`${message}`, "warning");
    };

    return (
        <Toolbar {...props} className={classes.toolbar_style}>
            <SaveButton onFailure={onFailure}/>
            <DeleteButton undoable={false}/>
        </Toolbar>
    );
}

export const lincencePlateFormatter = v => v?.toUpperCase().replace(/\s/g, "").trim();


export const AccidentCreate: FC<CreateProps> = (props) => {
    const classes = useStyles();
    return (
        <Create title="Kaza Kaydı Oluştur" {...props}>
            <TabbedForm validate={checkAccidentIsAddedAlready} redirect="list" toolbar={<AccidentCreateToolbar/>}>
                <FormTab label="Bilgiler">
                    <SectionTitle label="Dosya Bilgileri"/>
                    <ReferenceInput
                        reference="insurances"
                        source="insuranceId"
                        label="Sigorta Kodu"
                        formClassName={classes.inline_block} validate={required()}
                        filterToQuery={searchText => ({name__contains: searchText})}
                    >
                        <AutocompleteInput optionValue="id" optionText={insuranceNameCodeRenderer}/>
                    </ReferenceInput>
                    <TextInput source="notificationNumber" label="İhbar Kodu"
                               formClassName={classes.inline_block_margin} validate={required()}/>
                    <RadioButtonGroupInput source="responsibleInsuranceType" label="Sorumlu Sigorta"
                                           validate={required()}
                                           formClassName={classes.inline_block_margin}
                                           choices={[
                                               {id: '1', name: 'Trafik Sigortası'},
                                               {id: '2', name: 'Kasko Sigortası'}
                                           ]}/>

                    <Separator/>
                    <SectionTitle label="Müşteri Bilgileri"/>
                    <TextInput source="fullName" label="İsim Soyisim" formClassName={classes.inline_block}/>
                    <TextInput source="tcNumber" label="TC Numarası" formClassName={classes.inline_block_margin}
                               validate={validateTcNumber}/>
                    <TextInput source="taxNumber" label="Vergi Numarası" formClassName={classes.inline_block_margin}/>
                    <Separator/>
                    <SectionTitle label="Diğer Bilgiler"/>
                    <TextInput source="licencePlate" label="Plaka" 
                               parse={lincencePlateFormatter}
                               formClassName={classes.inline_block}
                               validate={required()}/>
                    <SelectInput source="accidentType" label="Kaza Tipi" formClassName={classes.inline_block_margin}
                                 validate={required()}
                                 choices={getAccidentTypeList()}
                                 optionText="name" optionValue="id"
                    />
                    <DateInput label="Kaza Tarihi" source="accidentDate" formClassName={classes.inline_block_margin}  />
                </FormTab>
                <FormTab label="Açıklama">
                    <RichTextInput source="description" label="Açıklama"/>
                </FormTab>
                <FormTab label="Dosyalar">
                    <ArrayInput source="appFiles">
                        <SimpleFormIterator>
                            <ReferenceInput reference="file-types" label="Dosya Tipi" source="fileTypeId">
                                <SelectInput source="fileTypeId" label="Dosya Tipi" optionText="typeName"
                                             optionValue="id"/>
                            </ReferenceInput>
                            <FileInput source="files" label="" multiple={true}
                                       placeholder={<p>Dosyaları sürükleyip bırakabilirsiniz</p>}>
                                <FileField source="src" title="title"/>
                            </FileInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}

const Separator = () => <Box pt="1em"/>;

const SectionTitle = ({label}: { label: string }) => {

    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};