// in src/MyAppBar.js
import * as React from 'react';
import { AppBar, Layout } from 'react-admin';
import {
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import { Logo } from './Logo';
import {AnkamProMenu} from "./AnkamProMenu";
import {useEffect, useState} from "react";
import axios from "axios";
import {apiUrl} from "../app-constants";

const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    spacer: {
        flex: 1,
    },
    logo: {
        width: "32px",
        height: "32px",
        padding: "4px 6px",
    },
    appTitle: {
        marginRight: "0.5rem",
        fontWeight: 700,
        color: "#cc0000",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    layoutTitle: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(2),
        },
    },
    expert: {
        color: "#fefefe",
        fontWeight: 700,
    },
    caps: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        textAlign: "center",
    },
    app_version: {
        fontSize: "10px"
    }
}));

export const AnkamProAppBar: React.FC = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [appVersion, setAppVersion] = useState("");


    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    
    useEffect(() => {
       axios.get(`${apiUrl}/system/info`)
           .then((response) => {
               setAppVersion(response.data.data);
           })
    },[]);

    return (
        <AppBar  {...props}>
            <div className={classes.logo}>
                <Logo />
            </div>
            <Typography variant="h6" color="inherit" className={classes.expert}>
                {isSmall ? "AP" : "Ankam PRO"}
            </Typography>
            <span className={classes.spacer} />
            {props.children}
            <label className={classes.app_version}>{appVersion}</label>
        </AppBar>
    );
};

export const AnkamProLayout: React.FC = (props) => {
    const styles = useStyles();
    return (
        <Layout {...props} appBar={AnkamProAppBar} menu={AnkamProMenu}>
            {/*<Typography*/}
            {/*    variant="h6"*/}
            {/*    color="inherit"*/}
            {/*    id="react-admin-title"*/}
            {/*    className={styles.layoutTitle}*/}
            {/*/>*/}
            {props.children}
        </Layout>
    );
};