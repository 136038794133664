import {FC} from "react";
import {
    EditProps,
    Edit,
    SimpleForm,
    TextInput,
    PasswordInput,
    SelectInput,
    required,
    email,
    NumberInput, ReferenceArrayInput, SelectArrayInput
} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from '@material-ui/core';

const useStyles = makeStyles({
    inline_block: {display: 'inline-block'},
    inline_block_margin: {display: 'inline-block', marginLeft: 32}
});

export const validatePasswords = ({password, confirm_password}: any) => {
    const errors = {} as any;
    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = 'Şifreler uyuşmuyor!';
    }
    return errors;
};

const requiredValidate = [required()];

export const UserEdit: FC<EditProps> = (props) => {
    const classes = useStyles();
    return (
        <Edit title="Kullanıcı Düzenle" {...props} undoable={false}>
            <SimpleForm validate={validatePasswords}>
                <TextInput source="fullName" label="İsim Soyisim" formClassName={classes.inline_block}
                           validate={requiredValidate}/>
                <TextInput source="userName" label="Kullanıcı Adı" formClassName={classes.inline_block_margin}
                           validate={requiredValidate}/>
                <Separator/>
                <TextInput source="email" label="Email Adresi" type="email" formClassName={classes.inline_block}
                           validate={[required(), email()]}/>
                <NumberInput source="phoneNumber" label="Telefon Numarası" formClassName={classes.inline_block_margin}
                             validate={requiredValidate}/>
                <Separator/>
                <PasswordInput source="password" label="Şifre" formClassName={classes.inline_block}  />
                <PasswordInput label="Şifre Tekrar" source="confirm_password"
                               formClassName={classes.inline_block_margin} />
                <Separator/>
                <ReferenceArrayInput source="roleNames" reference="roles" label="Rol/Roller" >
                    <SelectArrayInput optionText="name" optionValue="name" label="Rol/Roller" validate={requiredValidate} />
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    );
}

const Separator = () => <Box pt="1em"/>;