import {FC} from "react";
import {ListProps, List, Datagrid, TextField, FilterProps, Filter, TextInput, ReferenceInput, SelectInput, EditButton} from "react-admin";

export const insuranceNameCodeRenderer = insurance => `${insurance.name} - ${insurance.code}`;

const FileTypeListFilter:FC<Omit<FilterProps, 'children'>> = (props) => (
    <Filter {...props}>
        <TextInput source="name__contains" label="Dosya Tip İsmi" alwaysOn />
    </Filter>
);

export const FileTypeList:FC<ListProps> = (props) => (
    <List {...props} filters={<FileTypeListFilter />}>
        <Datagrid>
            <TextField source="typeName" label="Dosya Tipi" />
            <EditButton />
        </Datagrid>
    </List>
);