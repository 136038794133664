import {ApplicationRoles, getAccidentTypeList} from "../app-constants";
import {Card, CardContent, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {
    Button, required,
    SelectInput, SimpleForm, useDataProvider,
    useNotify,
    usePermissions
} from "react-admin";
import {useState} from "react";
import FileCopyIcon from '@material-ui/icons/FileCopy';


const AccidentCloneButton = ({onClick}) => (
    <Button
        label="Dosyayı Klonla"
        title="Dosyayı Klonla"
        onClick={onClick}
    >
        <FileCopyIcon />
    </Button>
);

export const AccidentClone = ({data}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const {permissions, loading: permissionLoading} = usePermissions();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    if (permissionLoading || loading) {
        return null;
    }

    const openModal = () => {
        setDialogOpen(true);
    }

    const closeModal = () => {
        setDialogOpen(false);
    }

    const handleSubmit = (values) => {
        setLoading(true);
        dataProvider.accidentClone({data: values})
            .then((response) => {
                if (response.success) {
                    notify(response.message, "success");
                } else {
                    notify(response.message, "error");
                }
                setLoading(false);
                setDialogOpen(false);
            })
            .catch(({response}) => {
                setLoading(false);
                setDialogOpen(false);
                notify(response.message, "error");
            })
    }


    const accidentDuplicateAcl = (): boolean => {
        return (permissions.includes(ApplicationRoles.Expert) ||
            permissions.includes(ApplicationRoles.Admin) ||
            permissions.includes(ApplicationRoles.SuperAdmin))
    }

    return accidentDuplicateAcl() ? (
        <>
            <AccidentCloneButton onClick={openModal}/>
            <Dialog open={dialogOpen} onClose={closeModal} fullWidth>
                <DialogTitle>Kaza Dosyasını Klonla</DialogTitle>
                <DialogContent>
                    <Card>
                        <CardContent>
                            <SimpleForm
                                title="Kaza Dosyasını Kolanla"
                                initialValues={{accidentId: data?.id}}
                                save={handleSubmit}
                                saving={loading}
                            >
                                    <SelectInput
                                        source="accidentType" 
                                        label="Dosya Tipi" 
                                        optionText="name" 
                                        optionValue="id" choices={getAccidentTypeList()}
                                        validate={required()}
                                        fullWidth />
                            </SimpleForm>
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        </>
    ) : null
}