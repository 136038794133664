// export const apiUrl = "http://localhost:8080/api" 
export const apiUrl = process.env.NODE_ENV === "development" ? "http://localhost:8080/api" : "https://ankampro.com/api";
export const reCAPTCHAKey = "6LdYVyIcAAAAAAbPoMTLCRdm4i6O-6At3SHH3ha9";

export enum ApplicationRoles {
    User = "User",
    Expert = "Expert",
    Admin = "Admin",
    SuperAdmin = "SuperAdmin",
    Technical = "Technical"
}

export enum AccidentStatus {
    Open = 0,
    Closed = 1,
    WaitingForExpertEvaluation = 2,
    ExpertEvaluated = 3,
    ExpertRejected = 4,
    WaitingCCCall = 5,
    Authorized = 6,
    UnAuthorized = 7,
    WaitingForCover = 8,
    CoverCreated = 9,
    WaitingForDocumentCollection = 10,
    DocumentCollected = 11,
    WaitingForDefectDraw = 12,
    DefectDrawImmutable = 13,
    DefectDrawDone = 14,
    WaitingForExpertApproval = 15,
    ExpertApproved = 16,
    MissingDocument = 17,
    ExpertNegative = 18,
    WaitingForSendInsurance = 19,
    SendedInsurance = 20
}

export enum AccidentType {
    Defect = 1,
    Depreciation = 2,
    DefectDepreciation= 3,
    DefectDamage = 4,
    DamageDifferent = 5,
    Scrapped = 6
}

export enum ProxyType
{
    UnLimited = 1,
    LimitedWithLicencePlate = 2,
    LimitedWithLicencePlateAndDate = 3,
    LimitedWithLicencePlateAndCrashDate = 4
}

let AccidentStatusDisplay: { [index: number]: string } = {};
AccidentStatusDisplay[AccidentStatus.Open] = "Açık";
AccidentStatusDisplay[AccidentStatus.Closed] = "Kapalı";
AccidentStatusDisplay[AccidentStatus.WaitingForExpertEvaluation] = "Eksper İncelemede Bekliyor";
AccidentStatusDisplay[AccidentStatus.ExpertEvaluated] = "Eksper İnceledi";
AccidentStatusDisplay[AccidentStatus.ExpertRejected] = "Eksper Reddetti";
AccidentStatusDisplay[AccidentStatus.WaitingCCCall] = "Çağrı Merkezi Arama Bekleniyor";
AccidentStatusDisplay[AccidentStatus.Authorized] = "Vekalet Alındı";
AccidentStatusDisplay[AccidentStatus.UnAuthorized] = "Vekalet Alınamadı";
AccidentStatusDisplay[AccidentStatus.WaitingForCover] = "Kapak Oluşturulması Bekleniyor";
AccidentStatusDisplay[AccidentStatus.CoverCreated] = "Kapak Oluşturuldu";
AccidentStatusDisplay[AccidentStatus.WaitingForDocumentCollection] = "Evrakların Toplanması Bekleniyor";
AccidentStatusDisplay[AccidentStatus.DocumentCollected] = "Evraklar Toplandı";
AccidentStatusDisplay[AccidentStatus.WaitingForDefectDraw] = "Kusur Çizim Bekleniyor";
AccidentStatusDisplay[AccidentStatus.DefectDrawImmutable] = "Kusur Değişmez";
AccidentStatusDisplay[AccidentStatus.DefectDrawDone] = "Kusur Çizimi Yapıldı";
AccidentStatusDisplay[AccidentStatus.WaitingForExpertApproval] = "Eksper Onayında Bekliyor";
AccidentStatusDisplay[AccidentStatus.ExpertApproved] = "Eksper Onayladı";
AccidentStatusDisplay[AccidentStatus.ExpertNegative] = "Eksper Olumsuz";
AccidentStatusDisplay[AccidentStatus.MissingDocument] = "Eksik Evrak";
AccidentStatusDisplay[AccidentStatus.WaitingForSendInsurance] = "Sigortaya Gönderilmeyi Bekliyor";
AccidentStatusDisplay[AccidentStatus.SendedInsurance] = "Sigortaya Gönderildi";

let AccidentTypeDisplay: { [index: number]: string } = {};
AccidentTypeDisplay[AccidentType.Defect] = "Kusur";
AccidentTypeDisplay[AccidentType.Depreciation] = "Değer Kaybı";
AccidentTypeDisplay[AccidentType.DefectDepreciation] = "Kusur - Değer Kaybı";
AccidentTypeDisplay[AccidentType.DefectDamage] = "Kusur - Hasar";
AccidentTypeDisplay[AccidentType.DamageDifferent] = "Hasar Farkı";
AccidentTypeDisplay[AccidentType.Scrapped] = "Pert";

let ProxyTypeDisplay: { [index: number]: string } = {};
ProxyTypeDisplay[ProxyType.UnLimited] = "Limitsiz";
ProxyTypeDisplay[ProxyType.LimitedWithLicencePlate] = "Plaka ile Sınırlı";
ProxyTypeDisplay[ProxyType.LimitedWithLicencePlateAndDate] = "Plaka ve Süre ile Sınırlı";
ProxyTypeDisplay[ProxyType.LimitedWithLicencePlateAndCrashDate] = "Plaka ve Kaza Tarihi ile Sınırlı";



export const getProxyTypeList = () => Object.keys(ProxyType)
    .filter((key) => parseInt(key))
    .map((key) => ({id:key, name:ProxyTypeDisplay[key]}));

export const getAccidentTypeList = () => Object.keys(AccidentType)
    .filter((key) => parseInt(key))
    .map((key) => ({id:key, name:AccidentTypeDisplay[key]}));

export const getAccidentStatusList = () => Object.keys(AccidentStatus)
    .filter((key) => parseInt(key))
    .map((key) => ({id:key, name:AccidentStatusDisplay[key]}));