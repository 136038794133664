import * as React from 'react';
import { FilterList, FilterListItem, FilterLiveSearch, TextInput } from 'react-admin';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';

import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
} from 'date-fns';

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const LastLoginsFilter = () => (
    <FilterList label="En Son Girişler" icon={<AccessTimeIcon />} >
        <FilterListItem label="Bugün" value={{
            insertedDate__gte:endOfYesterday().toISOString()
        }} />
        <FilterListItem label="Bu Hafta" value={{
            insertedDate__gte:startOfWeek(new Date()).toISOString()
        }} />
        <FilterListItem label="Geçen Hafta" value={{
            insertedDate__gte:subWeeks(startOfWeek(new Date()), 1).toISOString()
        }} />
        <FilterListItem label="Bu Ay" value={{
            insertedDate__gte:startOfMonth(new Date()).toISOString()
        }} />
        <FilterListItem label="Geçen Ay" value={{
            insertedDate__gte:subMonths(startOfMonth(new Date()), 1).toISOString()
        }} />
        <FilterListItem label="Daha Öncekiler" value={{
            insertedDate__lte:subMonths(startOfMonth(new Date()),1).toISOString()
        }} />
    </FilterList>
)

const LoginTypeFilter = () => (
    <FilterList label="Giriş Durumu" icon={<VpnKeyIcon />} >
        <FilterListItem label="Başarılı" value={{
            attemptStatus__eq:1
        }} />
        <FilterListItem label="Başarısız" value={{
            attemptStatus__eq:2
        }} />
    </FilterList>
)

export const SessionHistoryAsideFilter = () => (
    <Card>
        <CardContent>
            <FilterLiveSearch source="userName__contains"  />
            <LastLoginsFilter />
            <LoginTypeFilter />
        </CardContent>
    </Card>
);
