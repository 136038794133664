import {
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    TableCell,
    TableRow,
    Typography,
    Table,
    TableBody,
    TableContainer,
} from "@material-ui/core";
import {
    Button,
    DateField,
    ReferenceField,
    SelectField,
    ShowContextProvider,
    ShowProps,
    SimpleShowLayout,
    TextField,
    useGetOne,
    Loading,
    RecordContextProvider,
    BooleanField,
    SingleFieldList, ChipField, ArrayField, useRecordContext
} from "react-admin";
import {Fragment, useState} from "react";
import {ShowDocument} from "../accident/AccidentShow";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {BoxedShowLayout, RaBox} from "ra-compact-ui";
import {makeStyles} from "@material-ui/core/styles";
import {getProxyTypeList} from "../app-constants";
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 400,
    },
    text_h6: theme.typography.h6,
}));

export const HasNoProxy = () => (
    <Typography variant="h6">
        Yok
    </Typography>
);

export const LicencePlateChipField = (props) => {
    const {source} = props;
    const record = useRecordContext(props);
    if (!record) {
        return null;
    }
    return (
        <Fragment>
            {record[source]?.map(item => (
                <Chip label={item}/>
            ))}
        </Fragment>
    )
};


const ShowProxyButton = ({record, onClick}) =>
    record ? (
        <Button
            label="Vekaleti Görüntüle"
            title="Vekaleti Görüntüle"
            onClick={onClick}
        >
            <VisibilityIcon/>
        </Button>
    ) : <HasNoProxy/>;


export const ProxyShowModel = ({proxyId}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const classes = useStyles();

    const {data, loading, error} = useGetOne('proxies', proxyId);

    console.log({data});

    if (loading) {
        return <Loading/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    if (!data) {
        return null;
    }

    const openModal = () => {
        setDialogOpen(true);
    }

    const closeModal = () => {
        setDialogOpen(false);
    }

    return (
        <Fragment>
            <ShowProxyButton onClick={openModal} record={data}/>
            <Dialog open={dialogOpen} onClose={closeModal} fullWidth>
                <DialogTitle>Vekalet Bilgisi</DialogTitle>
                <DialogContent>
                    <RecordContextProvider value={data}>
                        <BoxedShowLayout>
                            <RaBox display="flex">
                                <RaBox flex="0 0 100%" display="flex" justifyContent="center">
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center"> T.C Numarası </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="tcNumber" label=""
                                                                   className={classes.text_h6}/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Vergi Numarası </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="taxNumber" label=""
                                                                   className={classes.text_h6}/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Müvekkil İsmi </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="clientNameSurname" label=""
                                                                   className={classes.text_h6}/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> İletişim Numarası </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="contactNumber" label=""
                                                                   className={classes.text_h6}/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Plaka/Plakalar </TableCell>
                                                    <TableCell align="center">
                                                        <LicencePlateChipField source="licencePlates"
                                                                               label="Plaka/Plakalar"/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Vekalet Tipi </TableCell>
                                                    <TableCell align="center">
                                                        <SelectField label="Vekalet Tipi"
                                                                     source="proxyType"
                                                                     choices={getProxyTypeList()}

                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Vekalet Bitiş Tarihi </TableCell>
                                                    <TableCell align="center">
                                                        <DateField source="dueDate" label="Vekalet Bitiş Tarihi"
                                                                   className={classes.text_h6}/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Vekalet Durmu </TableCell>
                                                    <TableCell align="center">
                                                        <SelectField label="Vekalet Durumu"
                                                                     source="proxyStatus"
                                                                     choices={[{id: 1, name: "Geçerli"}, {
                                                                         id: 2,
                                                                         name: "Süresi Doldu"
                                                                     }, {id: 3, name: "Geçersiz"}]}
                                                                     fullWidth
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Açıklama </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="description" label="Açıklama"
                                                                   className={classes.text_h6}/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Vekalet Dosyası</TableCell>
                                                    <TableCell align="center">
                                                        <ReferenceField label="Vekalet Dosyası" reference="files"
                                                                        source="fileId"
                                                                        link={false}>
                                                            <ShowDocument/>
                                                        </ReferenceField>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </RaBox>
                            </RaBox>
                        </BoxedShowLayout>
                    </RecordContextProvider>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}