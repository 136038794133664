import {FC, useState} from "react";
import {Card, CardContent, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {
    ArrayInput,
    Button, FileField, FileInput,
    FormDataConsumer,
    Loading, ReferenceInput,
    required,
    SelectInput,
    SimpleForm, SimpleFormIterator,
    useDataProvider,
    useNotify,
    usePermissions, useRefresh
} from "react-admin";
import {AccidentStatus, ApplicationRoles} from "../app-constants";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';


const ExpertApprovalButton = ({onClick, disabled}) => (
    <Button
        label="Onayla(Expert)"
        title="Expert Onay"
        onClick={onClick}
        disabled={disabled}
    >
        <AssignmentTurnedInIcon/>
    </Button>
);

export const ExpertApprovalDialog = ({data}) => {

    const {permissions, loading: permissionLoading} = usePermissions();
    const notify = useNotify();
    const [dialogOpen, setDialogOpen] = useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);

    if (permissionLoading) {
        return null;
    }

    const openModal = () => {
        setDialogOpen(true);
    }

    const closeModal = () => {
        setDialogOpen(false);
    }

    const handleSubmit = (values) => {
        setLoading(true);
        dataProvider.continueWorkflow({
            id: data?.id,
            data: {
                ...values,
                eventName: 'ExpertApproval'
            }
        }).then(response => {
            setDialogOpen(false);
            notify('İşlem Başarılı', "success");
            refresh();
        }).catch(error => {
            setLoading(false);
            notify(error.message, "error");
        })
    }

    //TODO: Kaza statüsü kontrolü düzgün çalışmıyor.(Workflow'da sorun olabilir.)
    const expertApprovalACL = (): boolean => ((
            permissions.includes(ApplicationRoles.Expert)
            || permissions.includes(ApplicationRoles.Admin)
            || permissions.includes(ApplicationRoles.SuperAdmin))
        && data?.accidentStatus == AccidentStatus.WaitingForExpertApproval);

    return expertApprovalACL() ? (
        <>
            <ExpertApprovalButton onClick={openModal} disabled={loading}/>
            <Dialog open={dialogOpen} onClose={closeModal} fullWidth>
                <DialogTitle>Expert Kaza Onay</DialogTitle>
                <DialogContent>
                    <Card>
                        <CardContent>
                            <SimpleForm save={handleSubmit}
                                        submitOnEnter={true}
                                        initialValues={{accidentId: data?.id}}
                                        saving={loading}
                            >
                                <SelectInput source="accidentStatus"
                                             label="Onay Durumu?"
                                             choices={[
                                                 {id: AccidentStatus.ExpertApproved, name: "Onay"},
                                                 {id: AccidentStatus.MissingDocument, name: "Eksik Evrak"},
                                                 {id: AccidentStatus.ExpertNegative, name: "Olumsuz(Kapat)"},
                                             ]} validate={required()} fullWidth/>
                            </SimpleForm>
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        </>
    ) : null
}