import {ApplicationRoles} from "../app-constants";
import {Card, CardContent, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {
    ArrayInput,
    Button, FileField, FileInput,
    ReferenceInput,
    required,
    SelectInput, SimpleForm, SimpleFormIterator, useDataProvider,
    useNotify,
    usePermissions,
    useRefresh
} from "react-admin";
import PostAddIcon from '@material-ui/icons/PostAdd';
import {useState} from "react";


const DocumentCollectButton = ({record, onClick}) => (
    <Button
        label="Döküman Ekle"
        title="Döküman Ekle"
        onClick={onClick}
    >
        <PostAddIcon/>
    </Button>
);

export const DocumentAddDialog = ({data}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const {permissions, loading: permissionLoading} = usePermissions();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    if (permissionLoading || loading) {
        return null
    }

    const openModal = () => {
        setDialogOpen(true);
    }

    const closeModal = () => {
        setDialogOpen(false);
    }

    const handleSubmit = (values) => {
        setLoading(true);
        dataProvider.addDocuments({data: values})
            .then((response) => {
                setLoading(false);
                setDialogOpen(false);
                refresh();
                if (response.success) {
                    notify(response.message, "success");
                } else {
                    notify(response.message, "error");
                }
            })
            .catch(({response}) => {
                setLoading(false);
                notify(response.message, "error");
            })
    }

    //TODO: Herkes döküman ekleyebilir mi? Sorulacak.
    const documentAddDialogAcl = (): boolean => {
        return (permissions.includes(ApplicationRoles.Expert) ||
            permissions.includes(ApplicationRoles.Admin) ||
            permissions.includes(ApplicationRoles.SuperAdmin));
    }
    return documentAddDialogAcl() ? (
        <>
            <DocumentCollectButton record={data} onClick={openModal}/>
            <Dialog open={dialogOpen} onClose={closeModal} fullWidth>
                <DialogTitle>Döküman Ekle</DialogTitle>
                <DialogContent>
                    <Card>
                        <CardContent>
                            <SimpleForm
                                title="Döküman Ekle"
                                initialValues={{accidentId: data?.id}}
                                save={handleSubmit}
                                saving={loading}
                            >
                                <ArrayInput source="appFiles" label="Dosya Ekle">
                                    <SimpleFormIterator>
                                        <ReferenceInput reference="file-types" label="Dosya Tipi" source="fileTypeId">
                                            <SelectInput source="fileTypeId" label="Dosya Tipi" optionText="typeName"
                                                         optionValue="id" validate={required()}/>
                                        </ReferenceInput>
                                        <FileInput source="files" label="Dosya" multiple={true} validate={required()}
                                                   placeholder={<p>Dosyaları sürükleyip bırakabilirsiniz</p>}>
                                            <FileField source="src" label="Dosya" title="title"/>
                                        </FileInput>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </SimpleForm>
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        </>
    ) : null
}