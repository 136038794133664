import {FC} from "react";
import {
    ListProps,
    List,
    Datagrid,
    TextField,
    TextInput,
    EditButton,
    Filter,
    FilterProps,
    SingleFieldList,
    ChipField,
    ArrayField
} from "react-admin";

const UserListFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <TextInput label="İsim Soyisim" source="fullName__contains" alwaysOn/>
        <TextInput label="Email" source="email__contains"/>
    </Filter>
)

export const UserList: FC<ListProps> = (props) => {

    return (
        <List {...props} filter={{company__eq:'Ankam'}} title="Kullanıcılar" filters={<UserListFilter/>}>
            <Datagrid>
                <TextField source="fullName" label="İsim Soyisim" />
                <TextField source="userName" label="Kullanıcı Adı" />
                <TextField source="email" label="Email" />
                <ArrayField source="roles" label="Roller">
                    <SingleFieldList>
                        <ChipField source="value"/>
                    </SingleFieldList>
                </ArrayField>
                <EditButton basePath="/users"/>
            </Datagrid>
        </List>
    )
}