import {FC} from "react";
import {
    Datagrid,
    DateField,
    Filter,
    FilterProps,
    List, ListProps,
    TextField,
    TextInput
} from "react-admin";
import {Box, Typography} from "@material-ui/core";


const AccidentUserSearchFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
    <Filter {...props} >
        <TextInput source="notificationNumber__eq" label="İhbar No" alwaysOn/>
    </Filter>
)

const Empty = () => (
    <Box textAlign="center" m={1}>
        <Typography variant="body1">
            Sonuçları görebilmek için filtreleme yapmanız gerekmektedir.
        </Typography>
    </Box>
)

export const AccidentUserSearch: FC<ListProps> = (props) => (
    <List empty={false} {...props} filters={<AccidentUserSearchFilter/>} exporter={false}>
        <Datagrid empty={<Empty/>}>
            <TextField source="notificationNumber" label="İhbar No"/>
            <TextField source="fullName" label="İsim Soyisim"/>
            <TextField source="email" label="Email"/>
            <DateField source="createdDate" label="Kaza Oluşturulma Tarihi"/>
        </Datagrid>
    </List>
);
