import {FC, useState} from "react";
import {
    EditProps,
    Edit,
    TextInput,
    SelectInput,
    DateInput,
    FormTab,
    required,
    FileInput,
    FileField,
    TabbedForm,
    Datagrid,
    TextField,
    DateField,
    ReferenceManyField,
    TopToolbar,
    ListButton,
    RefreshButton,
    ShowButton,
    Button,
    useDelete,
    useNotify,
    useRefresh,
    AutocompleteInput,
    ReferenceInput,
    NumberInput,
    SimpleFormIterator,
    ArrayInput,
    ReferenceField,
    usePermissions,
    Loading
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import {AddDescription, ShowDocument} from "./AccidentShow";
import {insuranceNameCodeRenderer} from "../insurance/InsuranceList";
import {AccidentCreateToolbar, lincencePlateFormatter} from "./AccidentCreate";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Swal from "sweetalert2";
import {createAxios} from "../providers/dataprovider";
import {AccidentStatus, apiUrl, ApplicationRoles, getAccidentTypeList} from "../app-constants";
import {PhoneInput} from "./PhoneInput";


const useStyles = makeStyles({
    inline_block: {display: 'inline-block'},
    inline_block_margin: {display: 'inline-block', marginLeft: 32},
});

const ApproveCoverButton = ({onClick, disabled}) => (
    <Button
        label="Kapak Tamamlandı"
        title="Kapak Tamamlandı"
        onClick={onClick}
        disabled={disabled}
    >
        <AssignmentTurnedInIcon />
    </Button>
);

//TODO:Refactor edilecek.
const AccidentEditActions = ({basePath, data, resource}) => {
    const {permissions, loading:permissionLoading} = usePermissions();
    const [loading, setLoading] = useState(false); 
    const notify = useNotify();
    if(permissionLoading){
        return <Loading />
    }
    const approveCoverHandle = (accidentId) => {
        setLoading(true);
        Swal.fire({
            title: 'Kapak yapıldı mı?',
            showCancelButton: true,
            confirmButtonText: 'Evet',
            cancelButtonText: 'Hayır'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const http = createAxios();
                const {data} = await http.post(`${apiUrl}/accidents/${accidentId}/cover-done`);
                if (!data.success) {
                    Swal.fire('İşlem yapılamadı', data.message, 'warning');
                    setLoading(false);
                    return;
                }
                Swal.fire('İşlem tamamlandı', '', 'success');
            }
        }).catch((response) => {
            setLoading(false);
            notify(response.message, "error");
        });
    }
    
    return (
        <TopToolbar>
            <ListButton basePath={basePath} label="Kazalar"/>
            <ShowButton basePath={basePath} record={data} label="Göster"/>
            <RefreshButton label="Yenile"/>
            {
                ((permissions.includes(ApplicationRoles.Technical) 
                    || permissions.includes(ApplicationRoles.SuperAdmin) 
                    || permissions.includes(ApplicationRoles.Admin)) 
                    && data?.accidentStatus === AccidentStatus.WaitingForCover) && 
                (
                        <ApproveCoverButton onClick={() => approveCoverHandle(data?.id)} disabled={loading}  />
                )
            }
        </TopToolbar>
    );
}


export const DeleteFileButton = (props) => {
    const {record} = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const [deleteOne, {loading, error, loaded}] = useDelete('files', record?.id, record);
    if (error) {
        notify(`Hata:${error.message}`, "error");
    }
    if (loaded) {
        notify("Dosya silindi", "success");
        refresh();
    }
    return (
        // @ts-ignore
        <Button disabled={loading} onClick={deleteOne} label="Sil">
            <DeleteIcon/>
        </Button>
    );
};

export const AccidentEdit: FC<EditProps> = (props) => {
    const classes = useStyles();
    
    // @ts-ignore
    return (
        // @ts-ignore
        <Edit undoable={false} title="Kaza Kaydı Güncelle" {...props} actions={<AccidentEditActions {...props} />} aside={<AddDescription accidentId={props.id}/>}>
            <TabbedForm redirect={false} toolbar={<AccidentCreateToolbar/>}>
                <FormTab label="Müşteri">
                    <SectionTitle label="Dosya Bilgileri"/>
                    <TextInput source="notificationNumber" label="İhbar Kodu"
                               formClassName={classes.inline_block} validate={required()}/>
                    <TextInput source="damageFileNumber" label="Hasar Dosya Kodu"
                               formClassName={classes.inline_block_margin}/>
                    <Separator/>

                    <SectionTitle label="Müşteri Bilgileri"/>
                    <TextInput source="fullName" label="İsim Soyisim" formClassName={classes.inline_block}/>
                    <TextInput source="tcNumber" label="TC Numarası" formClassName={classes.inline_block_margin}/>
                    <TextInput source="taxNumber" label="Vergi Numarası" formClassName={classes.inline_block_margin}/>
                    <Separator/>

                    <SectionTitle label="Sürücü Bilgileri"/>
                    <TextInput source="driverFullName" label="Sürücü İsim Soyisim"
                               formClassName={classes.inline_block}/>

                    <PhoneInput source="driverPhoneNumber" label="Sürücü Telefon Numarası" formClassName={classes.inline_block_margin} />
                    
                    <Separator/>

                    <SectionTitle label="Araç Bilgileri"/>
                    <TextInput source="licencePlate" label="Plaka" parse={lincencePlateFormatter} formClassName={classes.inline_block}/>
                    <TextInput source="carBrandModelPackage" label="Marka/Model"
                               formClassName={classes.inline_block_margin}/>
                    <NumberInput source="carModelYear" label="Model Yılı" formClassName={classes.inline_block_margin}/>
                    <Separator/>

                    <SectionTitle label="Trafik Sigorta Bilgileri"/>
                    <ReferenceInput
                        reference="insurances"
                        source="insuranceId"
                        label="Sigorta Kodu"
                        formClassName={classes.inline_block} validate={required()}
                        filterToQuery={searchText => ({name__contains: searchText})}
                    >
                        <AutocompleteInput optionValue="id" optionText={insuranceNameCodeRenderer}/>
                    </ReferenceInput>
                    <TextInput source="trafficInsuranceNumber" label="Trafik Sigorta Poliçe No"
                               formClassName={classes.inline_block_margin}/>
                    <DateInput source="trafficInsuranceExperyDate" label="Trafik Sigorta Bitiş Tarihi"
                               formClassName={classes.inline_block_margin}/>
                    <Separator/>

                    <SectionTitle label="Kasko Bilgileri"/>
                    <TextInput source="insuranceCompany" label="Kasko Şirketi" formClassName={classes.inline_block}/>
                    <TextInput source="insuranceNumber" label="Kasko Poliçe No"
                               formClassName={classes.inline_block_margin}/>
                    <DateInput source="insuranceExperyDate" label="Kasko Bitiş Tarihi"
                               formClassName={classes.inline_block_margin} initialValue={null}/>
                    <Separator/>

                    <SectionTitle label="Diğer Bilgiler"/>
                    <SelectInput source="accidentType" label="Kaza Tipi" formClassName={classes.inline_block}
                                 validate={required()}
                                 choices={getAccidentTypeList()}
                                 optionText="name" optionValue="id"
                    />
                    <DateInput label="Kaza Tarihi" source="accidentDate" formClassName={classes.inline_block_margin} />
                </FormTab>
                <FormTab label="Karşı Taraf Bilgileri">
                    <SectionTitle label="Kişi Bilgileri"/>
                    <TextInput source="opponent.fullName" label="İsim Soyisim" formClassName={classes.inline_block}/>
                    <TextInput source="opponent.tcNumber" label="Tc Numarası"
                               formClassName={classes.inline_block_margin}/>
                    <Separator/>
                    <SectionTitle label="Sürücü Bilgileri"/>
                    <TextInput source="opponent.driverFullName" label="Sürücü İsim Soyisim"
                               formClassName={classes.inline_block}/>
                    <TextInput source="opponent.driverTCNumber" label="Sürücü Tc Numarası"
                               formClassName={classes.inline_block_margin}/>
                    <PhoneInput source="opponent.phone" label="Telefon Numarası" formClassName={classes.inline_block_margin}/>
                    <Separator/>
                    <SectionTitle label="Araç Bilgileri"/>
                    <TextInput source="opponent.licencePlate" label="Plaka" formClassName={classes.inline_block}/>
                    <TextInput source="opponent.carBrandModelPackage" label="Marka/Model"
                               formClassName={classes.inline_block_margin}/>
                    <NumberInput source="opponent.carModelYear" label="Model Yılı"
                                 formClassName={classes.inline_block_margin} i/>
                    <Separator/>
                    <SectionTitle label="Trafik Sigorta Bilgileri"/>
                    <TextInput source="opponent.trafficInsuranceCompany" label="Sigorta Şirketi"
                               formClassName={classes.inline_block}/>
                    <TextInput source="opponent.trafficInsuranceNumber" label="Sigorta Poliçe No"
                               formClassName={classes.inline_block_margin}/>
                    <DateInput source="opponent.trafficInsuranceExperyDate" label="Sigorta Bitiş Tarihi"
                               formClassName={classes.inline_block_margin}/>
                    <Separator/>
                    <SectionTitle label="Kasko Bilgileri"/>
                    <TextInput source="opponent.insuranceCompany" label="Kasko Şirketi"
                               formClassName={classes.inline_block}/>
                    <TextInput source="opponent.insuranceNumber" label="Kasko Poliçe No"
                               formClassName={classes.inline_block_margin}/>
                    <DateInput source="opponent.insuranceExperyDate" label="Kasko Poliçe Bitiş Tarihi"
                               formClassName={classes.inline_block_margin}/>
                </FormTab>
                <FormTab label="Dosyalar">
                    <ReferenceManyField reference="files" target="accidentId" label="" fullWidth>
                        <Datagrid>
                            <ReferenceField label="Dosya Tipi" reference="file-types" source="appFileTypeId"
                                            link={false}>
                                <TextField source="typeName"/>
                            </ReferenceField>
                            <TextField source="name" label="Dosya İsmi"/>
                            <DateField source="createdDate" label="Eklenme Tarihi" showTime={true}/>
                            <ShowDocument/>
                            {/*@ts-ignore*/}
                            <DeleteFileButton/>
                        </Datagrid>
                    </ReferenceManyField>
                    <ArrayInput source="appFiles" label="Dosya Ekle">
                        <SimpleFormIterator>
                            <ReferenceInput reference="file-types" label="Dosya Tipi" source="fileTypeId">
                                <SelectInput source="fileTypeId" label="Dosya Tipi" optionText="typeName"
                                             optionValue="id"/>
                            </ReferenceInput>
                            <FileInput source="files" label="Dosya" multiple={true}
                                       placeholder={<p>Dosyaları sürükleyip bırakabilirsiniz</p>}>
                                <FileField source="src" label="Dosya" title="title"/>
                            </FileInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

const requiredValidate = [required()];

const Separator = () => <Box pt="1em"/>;

const SectionTitle = ({label}: { label: string }) => {

    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};