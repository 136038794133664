import {FC} from "react";
import {
    ListProps,
    List,
    Datagrid,
    TextField,
    TextInput,
    EditButton,
    BooleanField,
    Filter,
    FilterProps,
    ShowButton,
    SearchInput,
    DateInput,
    ReferenceField,
    usePermissions,
    DateField,
    FieldProps,
    AutocompleteArrayInput,
    SelectArrayInput,
    useGetIdentity, ReferenceInput, SelectInput
} from "react-admin";
import {AccidentType, ApplicationRoles, getAccidentStatusList, getAccidentTypeList} from "../app-constants";
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import Tooltip from '@material-ui/core/Tooltip';
import {excelExporter} from "./ExcelExporter";

const AccidentListFilter = (props: Omit<FilterProps, 'children'>) => {
    return (
        <Filter {...props}>
            <SearchInput source="fullName__contains" alwaysOn/>
            <TextInput source="notificationNumber__contains" label="İhbar Kodu" alwaysOn/>
            <AutocompleteArrayInput source="accidentStatus__in"
                                    label="Kaza Dosya Durumu"
                                    choices={getAccidentStatusList()} suggestionLimit={30}
                                    optionText="name" optionValue="id"
            />
            <TextInput label="TC No" source="tcNumber__contains"/>
            <TextInput label="Vergi No" source="taxNumber__contains"/>
            <ReferenceInput reference="users" 
                            source="createdUserId__eq"
                            filter={{ company__eq: 'Ankam' }}
                            label="Oluşturan Kişi">
                <SelectInput optionText="fullName" optionValue="id" label="Oluşturan Kişi" />
            </ReferenceInput>
            <TextInput source="insuranceCode__contains" label="Sigorta Kodu"/>
            <TextInput source="licencePlate__contains" label="Plaka"/>
            <SelectArrayInput source="accidentType__in" label="Kaza Tipi"
                              choices={getAccidentTypeList()}
                              optionText="name" optionValue="id"
            />
            <DateInput source="accidentDate__gte" label="Kaza Tarihi(>=)"/>
            <DateInput source="accidentDate__lte" label="Kaza Tarihi(<=)"/>
            <DateInput source="createdDate__gte" label="Oluşturulma Tarihi(>=)"/>
            <DateInput source="createdDate__lte" label="Oluşturulma Tarihi(<=)"/>
        </Filter>
    );
}

export const AccidentTypeField: FC<FieldProps> = ({record}) => {
    return record ? (
        record.accidentType === AccidentType.Depreciation ? (
            <Tooltip title="Değer Kaybı" placement="top">
                <TrendingDownIcon/>
            </Tooltip>
        ) : (
            <Tooltip title="Kusur" placement="top">
                <NewReleasesIcon/>
            </Tooltip>
        )
    ) : null;
}

export const AccidentList: FC<ListProps> = (props) => {
    const {permissions, loading: permissionLoading} = usePermissions();
    const {identity, loading: identityLoading} = useGetIdentity();

    if (permissionLoading || identityLoading) {
        return null;
    }

    const bulkActionsAcl = (): any => {
        return !!(permissions.includes(ApplicationRoles.Admin) || permissions.includes(ApplicationRoles.SuperAdmin));
    }

    const exportAcl = (): any => (
        (permissions.includes(ApplicationRoles.Admin) || permissions.includes(ApplicationRoles.SuperAdmin))
            ? excelExporter : false
    )
    const permanentFilterAcl = (): any => {
        if ((permissions.includes(ApplicationRoles.User) || permissions.includes(ApplicationRoles.Technical))) {
            return {createdUserId__eq: identity?.id};
        }
        return null;
    }

    return (
        <List {...props} bulkActionButtons={bulkActionsAcl()}
              filters={<AccidentListFilter/>}
              filter={permanentFilterAcl()}
              sort={{field: 'createdDate', order: 'DESC'}}
              exporter={exportAcl()}>
            <Datagrid>
                <TextField source="notificationNumber" label="İhbar No"/>
                <ReferenceField source="insuranceId" link={false} reference="insurances" label="Sigorta Firması">
                    <TextField source="name"/>
                </ReferenceField>

                <TextField source="fullName" label="İsim"/>
                <TextField label="Tc No" source="tcNumber" />
                <TextField label="Vergi No" source="taxNumber" />
                {/*<TextField source="tcNumber" label="Tc No"/>*/}
                <TextField source="licencePlate" label="Plaka"/>
                <BooleanField source="isAuthorized" label="Vekalet Durumu"/>
                {
                    (permissions.includes(ApplicationRoles.Admin) || permissions.includes(ApplicationRoles.SuperAdmin)) && (
                        <ReferenceField link={false} label="Oluşturan" reference="users" source="createdUserId">
                            <TextField source="fullName"/>
                        </ReferenceField>)
                }
                <DateField source="accidentDate" label="Kaza Tarihi"/>
                <DateField source="createdDate" label="Oluşturulma Tarihi" showTime/>
                <AccidentTypeField label="Tip"/>
                <TextField source="accidentStatusDescription" label="Statü"/>
                <ShowButton basePath="/accidents"/>
                {
                    (permissions.includes(ApplicationRoles.Admin) || permissions.includes(ApplicationRoles.SuperAdmin)) && (
                        <EditButton basePath="/accidents"/>
                    )
                }
            </Datagrid>
        </List>
    );
}