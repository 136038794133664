import {ApplicationRoles} from "../app-constants";
import {Card, CardContent, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {
    Button, ReferenceInput,
    SelectInput, SimpleForm, useDataProvider,
    useNotify,
    usePermissions,
    useRefresh
} from "react-admin";
import EditIcon from '@material-ui/icons/Edit';
import {useState} from "react";


const AccidentCodeUpdateButton = ({record, onClick}) => (
    <Button
        label="Dosya Kodu"
        title="Dosya Kodu"
        onClick={onClick}
    >
        <EditIcon />
    </Button>
);

export const AccidentCodeUpdateDialog = ({data}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const {permissions, loading: permissionLoading} = usePermissions();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    if (permissionLoading || loading) {
        return null;
    }

    const openModal = () => {
        setDialogOpen(true);
    }

    const closeModal = () => {
        setDialogOpen(false);
    }

    const handleSubmit = (values) => {
        setLoading(true);
        dataProvider.updateCode({data: values})
            .then((response) => {
                
                if (response.success) {
                    notify(response.message, "success");
                } else {
                    notify(response.message, "error");
                }
                setLoading(false);
                setDialogOpen(false);
                refresh();
            })
            .catch(({response}) => {
                setLoading(false);
                setDialogOpen(false);
                notify(response.message, "error");
            })
    }


    const accidentCodeUpdateAcl = (): boolean => {
        return (permissions.includes(ApplicationRoles.Expert) ||
            permissions.includes(ApplicationRoles.Admin) ||
            permissions.includes(ApplicationRoles.SuperAdmin))
    }
    return accidentCodeUpdateAcl() ? (
        <>
            <AccidentCodeUpdateButton record={data} onClick={openModal}/>
            <Dialog open={dialogOpen} onClose={closeModal} fullWidth>
                <DialogTitle>Dosya Kodu</DialogTitle>
                <DialogContent>
                    <Card>
                        <CardContent>
                            <SimpleForm
                                title="Dosya Kodu"
                                initialValues={{accidentId: data?.id}}
                                save={handleSubmit}
                                saving={loading}
                            >
                                <ReferenceInput reference="accident-codes" label="Dosya Kodu" source="accidentCodeId" fullWidth>
                                    <SelectInput source="accidentCodeId" label="Dosya Kodu" optionText="code" optionValue="id" fullWidth />
                                </ReferenceInput>
                            </SimpleForm>
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        </>
    ) : null
}

//TODO: Code update işlemi ResouceContextProvider ile yapılabilir.