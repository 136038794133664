import {
    CreateParams,
    CreateResult,
    DataProvider,
    DeleteManyParams,
    DeleteManyResult,
    DeleteParams,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetManyParams,
    GetManyReferenceParams,
    GetManyReferenceResult,
    GetManyResult,
    GetOneParams,
    GetOneResult,
    UpdateManyParams,
    UpdateManyResult,
    UpdateParams,
    UpdateResult,
} from "ra-core";
import {HttpError} from 'react-admin';
import axios, {AxiosInstance, AxiosRequestConfig} from "axios";

export const initAxiosInterceptors = (instance: AxiosInstance = axios) => {
    instance.interceptors.request.use(value => {
        const existingAuth = value.headers["Authorization"];
        if (existingAuth !== undefined) {
            return value;
        }

        const token = localStorage.getItem("token");
        value.headers["Authorization"] = `Bearer ${token}`;
        return value;
    });
    instance.interceptors.response.use(value => {
        if ([401, 403].includes(value.status)) {
            console.log("axios auth error", value.status);
            // window.location.hash = '#/login';
        }
        return value;
    });
};

export const createAxios = (config?: AxiosRequestConfig): AxiosInstance => {
    const instance = axios.create(config);
    initAxiosInterceptors(instance);
    return instance;
};

export interface IListResponse<T = unknown> {
    totalCount: number;
    items: T[];
}

export interface IPagedListInput {
    pageSize?: number;
    pageIndex?: number;
    sorts?: string | string[];
    filter?: string | string[];
}

export interface IDataResult {
    success: boolean;
    message?: string;
    data?: any;
}

interface FilterOperations {
    [key: string]: (field: string, value: any) => string;
}

const FILTERS: FilterOperations = {
    eq: (f, v) => `${f} == ${JSON.stringify(v)}`,
    neq: (f, v) => `${f} != ${JSON.stringify(v)}`,

    gt: (f, v) => `${f} > ${JSON.stringify(v)}`,
    gte: (f, v) => `${f} >= ${JSON.stringify(v)}`,
    lt: (f, v) => `${f} < ${JSON.stringify(v)}`,
    lte: (f, v) => `${f} <= ${JSON.stringify(v)}`,
    contains: (f, v) => `${f}.toLower().contains(${JSON.stringify(v)}.toLower())`, //Case insensitive
    cscontains: (f, v) => `${f}.contains(${JSON.stringify(v)})`, // Case sensitive
    // build a string of (field == value) || (field == another value)
    in: (f, values: any[]) => `${f} in (${values.map(v => JSON.stringify(v)).join(', ')})`,
};

const makeFilter = (field: string, op: string, value: string): string => {
    const filter = FILTERS[op];
    if (filter === undefined) {
        throw new Error(`Invalid operation. Only ${Object.keys(FILTERS).join(", ")} are supported.`);
    }

    return filter(field, value);
};

const makeListRequestParams = (params: GetListParams): IPagedListInput => {
    const consoleTableObj: any = {};
    const {page, perPage} = params.pagination;
    const {order, field} = params.sort;
    // const flatFilters = flatten(params.filter);
    const flatFilters = params.filter
    const filters = Object.keys(flatFilters)
        .map((k) => {
            if (!k.includes("__")) {
                // @ts-ignore
                const customLinqFilter: string = `${k.replace("$", JSON.stringify(flatFilters[k]))}`;
                if (process.env.NODE_ENV === "development") {
                    // @ts-ignore
                    Object.assign(consoleTableObj, {
                        [customLinqFilter]: {
                            operation: 'custom',
                            // @ts-ignore
                            value: JSON.stringify(flatFilters[k]),
                            linq: customLinqFilter,
                        },
                    });
                }
                return customLinqFilter;
            }

            // @ts-ignore
            const value = flatFilters[k];
            const [field, op] = k.split("__");
            const linqFilter: string = makeFilter(field, op, value);
            if (process.env.NODE_ENV === "development") {
                // @ts-ignore
                Object.assign(consoleTableObj, {
                    [field]: {
                        operation: op,
                        // @ts-ignore
                        value: JSON.stringify(flatFilters[k]),
                        linq: linqFilter,
                    },
                });
            }
            return linqFilter;
        });

    if (process.env.NODE_ENV === "development" && filters.length > 0) {
        const label = "Filters";
        console.groupCollapsed(label);
        console.table(consoleTableObj);
        console.info({createdQuery: filters.map((f) => `(${f})`).join(" && ")})
        console.groupEnd();
    }

    return {
        pageIndex: page - 1,
        pageSize: perPage,
        sorts: `${field} ${order}`,
        filter: filters.map((f) => `(${f})`).join(" && "),
    };
};

export const flatten = (target: any, opts: { delimiter?: string; maxDepth?: number; safe?: boolean } = {}): any => {
    opts = opts || {};
    const delimiter = opts.delimiter || ".";
    const maxDepth = opts.maxDepth;
    const transformKey = (k: string) => k;
    const output = {} as any;

    function step(object: { [x: string]: any; }, prev: string | undefined, currentDepth: number | undefined) {
        currentDepth = currentDepth || 1;
        Object.keys(object).forEach(function (key) {
            const value = object[key];
            const isarray = opts.safe && Array.isArray(value);
            const type = Object.prototype.toString.call(value);
            const isobject = type === "[object Object]" || type === "[object Array]";
            const newKey = prev ? prev + delimiter + transformKey(key) : transformKey(key);
            // @ts-ignore
            if (!isarray && isobject && Object.keys(value).length && (!opts.maxDepth || currentDepth < maxDepth)) {
                // @ts-ignore
                return step(value, newKey, currentDepth + 1);
            }
            // @ts-ignore
            output[newKey] = value;
        });
    }

    // @ts-ignore
    step(target);
    return output;
};

interface TtmsDataProviderConfig {
    axios?: AxiosInstance;
}

export interface TtmsDataProvider extends DataProvider {
    http: AxiosInstance;
    baseUrl: string;
}

export const ankamProDataProvider = (baseUrl: string, config?: TtmsDataProviderConfig): TtmsDataProvider => {
    const http = config?.axios || createAxios();

    const urlOf = (...parts: any[]): string => [baseUrl, ...parts].map((p) => `${p}`.replace(/^\/|\/$/g, "")).join("/");

    //@ts-ignore
    function convertToFormData(data: any, formData: FormData = null, parentKey = ''): FormData {
        formData = formData || new FormData();
        if (!data) {
            return formData;
        }
        const fileFields = findFileFields(data);
        Object.keys(data).filter(f => !fileFields?.includes(f)).map((dataKey, index) => {
            let formKey = parentKey ? `${parentKey}.${dataKey}` : dataKey;

            if (data[dataKey] instanceof Array) {
                if (typeof data[dataKey][0] !== 'object') {
                    data[dataKey].forEach((element, index) => {
                        formData.append(formKey, data[dataKey][index]);
                    });
                } else {

                    data[dataKey].forEach((element, index) => {
                        const tempFormKey = `${formKey}[${index}]`;
                        convertToFormData(element, formData, tempFormKey);
                    });
                }
            } else {
                if (typeof data[dataKey] === 'object') {
                    convertToFormData(data[dataKey], formData, formKey);
                }
                if (data[dataKey]) { // value doluysa
                    formData.append(formKey, data[dataKey]);
                }
            }
        });
        fileFields
            .filter(f => data[f]?.length > 0)
            .map((file, index) => {
                // @ts-ignore
                const dataFile = data[file];
                // @ts-ignore
                const dataFileIndex = data[file][index];
                // @ts-ignore
                Object.values(data[file])
                    .map((f, index) => {
                            //@ts-ignore    
                            parentKey ? formData.append(`${parentKey}.${file}`, f.rawFile) : formData.append(`${file}`, f.rawFile)
                        }
                    )
            });

        return formData;
    }

    return {
        http,
        baseUrl,
        async create<RecordType>(resource: string, params: CreateParams): Promise<CreateResult<RecordType>> {
            const formData = convertToFormData(params.data);

            const {data: {data}} = await http.post(urlOf(resource), formData, {headers: {'content-type': 'multipart/form-data'}});
            return {data};
        },
        async delete<RecordType>(resource: string, params: DeleteParams): Promise<DeleteResult<RecordType>> {
            const {data: {data}} = await http.delete(urlOf(resource, params.id));
            return {data};
        },
        async deleteMany(resource: string, params: DeleteManyParams): Promise<DeleteManyResult> {
            const results = await Promise.all(params.ids.map((id) => http.delete(urlOf(resource, id))));
            return {
                data: results.map((r: any) => r.data),
            };
        },
        async getList<RecordType>(resource: string, params: GetListParams): Promise<GetListResult<RecordType>> {
            const {data} = await http.get<IListResponse<RecordType>>(urlOf(resource), {
                params: makeListRequestParams(params),
            });
            return {
                data: data.items,
                total: data.totalCount,
            };
        },
        async getMany<RecordType>(resource: string, params: GetManyParams): Promise<GetManyResult<RecordType>> {
            const filter = params.ids.map((id) => `id == ${JSON.stringify(id)}`).join(" || ");
            const {data} = await http.get<IListResponse<RecordType>>(urlOf(resource), {
                params: {
                    filter: filter,
                    pageSize: 100,
                } as IPagedListInput,
            });
            return {data: data.items};
        },
        async getManyReference<RecordType>(
            resource: string,
            params: GetManyReferenceParams
        ): Promise<GetManyReferenceResult<RecordType>> {
            const {data} = await http.get<IListResponse<any>>(urlOf(resource), {
                params: {
                    filter: `${params.target}==${JSON.stringify(params.id)}`,
                    sorts: `${params.sort.field} ${params.sort.order}`,
                    pageIndex: params.pagination.page - 1,
                    pageSize: params.pagination.perPage,
                },
            });
            return {data: data.items, total: data.totalCount};
        },
        async getOne<RecordType>(resource: string, params: GetOneParams): Promise<GetOneResult<RecordType>> {
            const {data: {data}} = await http.get(urlOf(resource, params.id));
            return {data};
        },
        async update<RecordType>(resource: string, params: UpdateParams): Promise<UpdateResult<RecordType>> {
            console.log({params});
            const formData = convertToFormData(params.data);
            const {data: {data}} = await http.put(urlOf(resource), formData);
            return {data};
        },
        async updateMany(resource: string, params: UpdateManyParams): Promise<UpdateManyResult> {
            const results = await Promise.all(params.ids.map((id) => http.put(urlOf(resource, id), {id, ...params.data})));
            return {
                data: results.map((r: any) => r.data),
            };
        },
        async continueWorkflow(params:CreateParams): Promise<IDataResult> {
            const formData = convertToFormData(params.data);
            const {data} = await http.post(urlOf("accidents/continue-workflow"), formData);
            return {data: data.data, success: data.success, message: data.message};
        },
        async addDocuments(params: CreateParams): Promise<IDataResult> {
            const formData = convertToFormData(params.data);
            const {data} = await http.post(urlOf("accidents/add-documents"), formData);
            return {data: data.data, success: data.success, message: data.message};
        },
        async updateCode(params: UpdateParams): Promise<IDataResult> {
            const formData = convertToFormData(params.data);
            const {data} = await http.put(urlOf("accidents/code"), formData);
            return {data: data.data, success: data.success, message: data.message};
        },
        async expertEvaluation(params: CreateParams): Promise<IDataResult> {
            const formData = convertToFormData(params.data);
            const {data} = await http.post(urlOf("accidents/expert-evaluation"), formData);
            return {data: data.data, success: data.success, message: data.message};
        },
        async accidentClone(params: UpdateParams): Promise<IDataResult> {
            const formData = convertToFormData(params.data);
            const {data} = await http.put(urlOf("accidents/clone"), formData);
            return {data: data.data, success: data.success, message: data.message};
        },
    };
};

function findFileFields(formData: object): string[] {
    if (formData) {
        return Object.keys(formData).filter((k) => {
            // @ts-ignore
            const val = formData[k];
            const isSingleFile = val?.rawFile instanceof File;
            const isMultipleFiles = Array.isArray(val) && val.some((item) => item.rawFile instanceof File);
            return isSingleFile || isMultipleFiles;
        });
    }

    return [];
}

