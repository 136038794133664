import {FC} from "react";
import {FieldProps} from "react-admin";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles(({
    success: {
        color:"green"
    },
    fail:{
        color:"red"
    }
}));


export const AttemptStatuField:FC<FieldProps> = ({record}) => {
    const classes = useStyle();
    return record ? (
        <span>
            {
                record.attemptStatus && ( record.attemptStatus == 1 ? <ThumbUpIcon className={classes.success} /> : <ThumbDownIcon className={classes.fail} /> )
            }
        </span>
    ): null;
}