import {useState} from "react";
import {Card, CardContent, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {
    Button, required,
    SelectInput,
    SimpleForm, useDataProvider,
    useNotify,
    usePermissions, useRefresh
} from "react-admin";
import {AccidentStatus, ApplicationRoles} from "../app-constants";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

const ExpertEvaluationButton = ({onClick, disabled}) => (
    <Button
        label="İncele(Expert)"
        title="Expert Değerlendirmesi"
        onClick={onClick}
        disabled={disabled}
    >
        <AssignmentIndIcon />
    </Button>
);

export const ExpertEvaluationDialog = ({data}) => {

    const {permissions, loading: permissionLoading} = usePermissions();
    const notify = useNotify();
    const refresh = useRefresh();
    const [dialogOpen, setDialogOpen] = useState(false);
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);

    if (permissionLoading) {
        return null;
    }

    const openModal = () => {
        setDialogOpen(true);
    }

    const closeModal = () => {
        setDialogOpen(false);
    }

    const handleSubmit = (values) => {
        setLoading(true);
        dataProvider.continueWorkflow({
            id: data?.id,
            data: {
                ...values,
                eventName: 'ExpertEvaluation'
            }
        }).then(response => {
            setDialogOpen(false);
            refresh();
            notify('İşlem Başarılı', "success");
        }).catch(error => {
            setLoading(false);
            notify(error.message, "error");
        })
    }

    const expertEvaluationACL = (): boolean => ((
            permissions.includes(ApplicationRoles.Expert)
            || permissions.includes(ApplicationRoles.Admin)
            || permissions.includes(ApplicationRoles.SuperAdmin))
        && data?.accidentStatus == AccidentStatus.WaitingForExpertEvaluation);

    return expertEvaluationACL() ? (
        <>
            <ExpertEvaluationButton onClick={openModal} disabled={loading} />
            <Dialog open={dialogOpen} onClose={closeModal} fullWidth>
                <DialogTitle>Expert Kaza İncele</DialogTitle>
                <DialogContent>
                    <Card>
                        <CardContent>
                            <SimpleForm save={handleSubmit}
                                        submitOnEnter={true}
                                        initialValues={{accidentId: data?.id}}
                                        saving={loading}
                            >
                                <SelectInput source="accidentStatus"
                                             label="İnceleme Durumu?"
                                             choices={[
                                                 {id: AccidentStatus.ExpertEvaluated, name: "İncelendi"},
                                                 {id: AccidentStatus.ExpertRejected, name: "Reddedildi"}
                                             ]} validate={required()} fullWidth/>
                            </SimpleForm>
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        </>
    ) : null
}