import * as React from 'react';
import {FC, FunctionComponent, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {
    Button,
    CardActions,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useTranslate, useLogin, useNotify, useSafeSetState} from 'ra-core';
import ReCAPTCHA from "react-google-recaptcha";
import {reCAPTCHAKey} from "../app-constants";
import {Login} from "react-admin";
import {Logo} from '../layout/Logo';
import {codeSendService, codeVerifyService, loginService, LoginValues} from "../providers/authProvider";

interface Props {
    redirectTo?: string;
}


const useStyles = makeStyles(
    (theme: Theme) => ({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing(1),
        },
        recapthca_error: {
            marginTop: '1em',
            border: '1px solid red'
        },
        recapthca_error_message: {
            color: 'red'
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        logo: {
            width: "64px",
            height: "64px",
            padding: "4px 6px",
        }
    }),
    {name: 'RaLoginForm'}
);

const Input = ({
                   meta: {touched, error}, // eslint-disable-line react/prop-types
                   input: inputProps, // eslint-disable-line react/prop-types
                   ...props
               }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

let jwtToken: string = "";

const LoginForm: FunctionComponent<Props> = props => {

    const {redirectTo} = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [reCapthcaError, setreCapthcaError] = useState<boolean>(false);
    const [isLoginSuccess, setIsLoginSuccess] = useState<boolean>(false);
    // const [jwtToken, setJwtToken] = useState<any>();
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles(props);
    let recaptchaRef = useRef<ReCAPTCHA>();

    const validate = (values: any) => {

        const errors = {username: undefined, password: undefined};

        const recaptchaValue = recaptchaRef.current

        if (!values.username) {
            // @ts-ignore
            errors.username = "Kullanıcı Zorunlu!";
        }
        if (!values.password) {
            // @ts-ignore
            errors.password = "Şifre Zorunlu!"
        }
        return errors;
    };

    const reCAPTCHAOnChange = (value) => {
        if (!value) {
            setreCapthcaError(false);
            return;
        }
    }

    const reCAPTCHAOnErrored = (error) => {
        console.log(error);
    }

    const handleLoginFormSubmit = async (values: LoginValues) => {

        setLoading(true);
        const captchaValue = recaptchaRef?.current?.getValue();

        if (!captchaValue) {
            setreCapthcaError(true);
            setLoading(false);
            return;
        } else {
            setreCapthcaError(false);
        }

        values.captchaToken = captchaValue;

        const loginResult = await loginService(values);
        
        if (loginResult?.success === false) {
            notify(loginResult.message, "error");
            setLoading(false);
            recaptchaRef?.current?.reset();
            setIsLoginSuccess(false);
            return;
        }

        jwtToken = loginResult?.data?.token;

        const codeSendResult = await codeSendService(jwtToken);

        if (codeSendResult?.success !== true) {
            notify(codeSendResult.message, "error");
            setLoading(false);
            setIsLoginSuccess(false);
            return;
        }

        setLoading(false);
        setIsLoginSuccess(true);
    };

    const handleVerificationFormSubmit = async ({code}) => {
        const codeVerifyResult = await codeVerifyService(code, jwtToken);

        if (codeVerifyResult?.success !== true) {
            notify(codeVerifyResult.message, "error");
            setLoading(false);
            return;
        }

        await login(jwtToken)
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                notify(error?.message, "error");
            });

    }

    return (!isLoginSuccess ? (
            <Form
                onSubmit={handleLoginFormSubmit}
                validate={validate}
                render={({handleSubmit}) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    id="username"
                                    name="username"
                                    component={Input}
                                    label="Kullanıcı Adı"
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    id="password"
                                    name="password"
                                    component={Input}
                                    label="Şifre"
                                    type="password"
                                    disabled={loading}
                                    autoComplete="current-password"
                                />
                            </div>
                            <div className={reCapthcaError ? classes.recapthca_error : classes.input}>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={reCAPTCHAKey}
                                    onChange={reCAPTCHAOnChange}
                                    onErrored={reCAPTCHAOnErrored}
                                />
                                {
                                    reCapthcaError &&
                                    (
                                        <span className={classes.recapthca_error_message}>
                                        Lütfen bir robot olmadığınızı doğrulayın.
                                    </span>
                                    )
                                }

                            </div>
                        </div>
                        <CardActions>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                            >
                                {loading && (
                                    <CircularProgress
                                        className={classes.icon}
                                        size={18}
                                        thickness={2}
                                    />
                                )}
                                Giriş Yap
                            </Button>
                        </CardActions>
                    </form>
                )}
            />
        ) : (
            <Form
                onSubmit={handleVerificationFormSubmit}
                validate={validate}
                render={({handleSubmit}) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    id="code"
                                    name="code"
                                    component={Input}
                                    label="Sms Kodu"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <CardActions>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                            >
                                {loading && (
                                    <CircularProgress
                                        className={classes.icon}
                                        size={18}
                                        thickness={2}
                                    />
                                )}
                                Doğrula ve Giriş Yap
                            </Button>
                        </CardActions>
                    </form>
                )}
            />
        )
    );
};

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};

const AnkamProLogin = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {/*<div className={classes.avatar}>*/}
            {/*    <div className={classes.logo}>*/}
            {/*        <Logo/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <Login>
                <LoginForm/>
            </Login>
        </React.Fragment>
    )
}

export default AnkamProLogin;