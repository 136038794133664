import {AccidentList} from "./AccidentList";
import {AccidentCreate} from "./AccidentCreate";
import {AccidentEdit} from "./AccidentEdit";
import {AccidentShow} from "./AccidentShow";


const resources = {
    list: AccidentList,
    create: AccidentCreate,
    edit: AccidentEdit,
    show: AccidentShow
};

export default resources;