import {FC} from "react";
import {
    Datagrid,
    DateField,
    DateInput, FieldProps,
    Filter,
    List,
    ListProps,
    NumberField,
    NumberInput,
    TextField,
    TextInput
} from "react-admin";
import { AttemptStatuField } from "./AttemptStatusField";
import {SessionHistoryAsideFilter} from "./SessionHistoryAsideFilters";


const SessionHistoryFilters: FC = (props) => (
    <Filter {...props}>
        <TextInput source="fullName__contains" label="İsim & Soyisim"/>
        <TextInput source="ipAddress__contains" label="IP Adres"/>
        <NumberInput source="tryCount__gte" label="Deneme Sayısı" />
        <DateInput source="insertedDate__gte" label="Giriş Tarihi (>=)" />
        <DateInput source="insertedDate__lte" label="Giriş Tarihi (<=)" />
    </Filter>
)

export const SessionHistoryList: FC<ListProps> = (props) => {

    return (
        <List {...props} title="Kullanıcı Giriş Geçmişi" aside={<SessionHistoryAsideFilter />} filters={<SessionHistoryFilters />}>
            <Datagrid isRowSelectable={row => false} hasBulkActions={false} >
                <TextField source="fullName" label="İsim & Soyisim"/>
                <TextField source="userName" label="Kullanıcı Adı"/>
                <TextField source="ipAddress" label="IP Adres" />
                <NumberField source="tryCount" label="Deneme Sayısı" />
                <AttemptStatuField label="Giriş Durumu" />
                <DateField source="insertedDate" label="Giriş Tarihi" showTime={true} />
            </Datagrid>
        </List>
    )
}