import {FC} from "react";
import {
    Datagrid,
    DateField,
    Filter,
    FilterProps,
    List,
    Loading,
    ReferenceField,
    ResourceContextProvider,
    SelectArrayInput,
    TextField,
    TextInput,
    usePermissions
} from "react-admin";
import {AccidentStatus, ApplicationRoles} from "../app-constants";
import {AccidentTypeField} from "./AccidentList";
import * as React from "react";
import { excelExporter } from "./ExcelExporter";


const AccidentExpertList2Filter = (props: Omit<FilterProps, 'children'>) => {
    return (
        <Filter {...props}>
            <TextInput label="İhbar No" source="notificationNumber__contains" alwaysOn/>
            <TextInput label="Tc No" source="tcNo__contains" alwaysOn/>
            {/*<SelectArrayInput label="Statü" source="accidentStatu__in" choices={[*/}
            {/*    {id: AccidentStatus.WaitingForExpertEvaluation, name: 'Exper İnceleme Bekleyenler'},*/}
            {/*    {id: AccidentStatus.WaitingForExpertApproval, name: 'Exper Onay Bekleyenler'},*/}
            {/*    {id: AccidentStatus.WaitingForDefectDraw, name: 'Kusur Çizim Bekleyenler'}*/}
            {/*]}/>*/}
        </Filter>
    );
}

export const AccidentExpertList2: FC = () => {

    const {permissions, loading} = usePermissions();
    if (loading) {
        return <Loading/>;
    }

    const exportAcl = ():any => (
        (permissions.includes(ApplicationRoles.Admin) || permissions.includes(ApplicationRoles.SuperAdmin))
            ? excelExporter : false
    );

    return (
        <ResourceContextProvider value="accidents">
            <List syncWithLocation={false} 
                  basePath="/accidents"
                  filter={{accidentStatus__in: [ AccidentStatus.WaitingForExpertApproval, AccidentStatus.WaitingForExpertEvaluation, AccidentStatus.WaitingForDefectDraw]}}
                  filters={<AccidentExpertList2Filter/>}
                  exporter={exportAcl()}
                  sort={{field: 'createdDate', order: 'DESC'}}>
                <Datagrid rowClick="show">
                    <TextField source="notificationNumber" label="İhbar No"/>
                    <ReferenceField source="insuranceId" link={false} reference="insurances"
                                    label="Sigorta Firması">
                        <TextField source="name"/>
                    </ReferenceField>
                    <TextField source="fullName" label="İsim"/>
                    <TextField source="tcNumber" label="Tc No"/>
                    <TextField source="licencePlate" label="Plaka"/>
                    <DateField source="createdDate" label="Oluşturulma Tarihi" showTime/>
                    <AccidentTypeField label="Tip"/>
                    <TextField source="accidentStatusDescription" label="Statü"/>
                </Datagrid>
            </List>
        </ResourceContextProvider>
    );
}

