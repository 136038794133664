import { FC } from "react";
import {CreateProps, Create, SimpleForm, TextInput, required} from "react-admin";

export const AccidentCodeCreate:FC<CreateProps> = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="code" label="Kod" validate={required()} />
            <TextInput source="codeNickname" label="Kod Takma Adı" />
            <TextInput source="description" label="Açıklama" />
        </SimpleForm>
    </Create>
);