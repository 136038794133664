import React from "react";
import {ListProps, List, Datagrid, TextField, FilterProps, Filter, TextInput, ReferenceInput, SelectInput, EditButton} from "react-admin";

export const insuranceNameCodeRenderer = insurance => `${insurance.name} - ${insurance.code}`;

const InsuranceListFilter:React.FC<Omit<FilterProps, 'children'>> = (props) => (
    <Filter {...props}>
        <TextInput source="name__contains" label="Şirket İsmi" alwaysOn />
        <TextInput source="code__contains" label="Şirket Kodu" />
        <ReferenceInput source="id__eq" label="Şirket Ara" reference="insurances">
            <SelectInput optionText={insuranceNameCodeRenderer} optionValue="id" />
        </ReferenceInput>
    </Filter>
);

export const InsuranceList:React.FC<ListProps> = (props) => (
    <List {...props} filters={<InsuranceListFilter />}>
        <Datagrid>
            <TextField source="name" label="Şirket İsmi" />
            <TextField source="code" label="Şirket Kodu" />
            <EditButton />
        </Datagrid>
    </List>
);