import {
    ListProps,
    List,
    Datagrid,
    TextField,
    FilterProps,
    Filter,
    TextInput,
    ReferenceInput,
    SelectInput,
    EditButton, required
} from "react-admin";
import {FC} from "react";


const AccidentCodeFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
    <Filter {...props}>
        <TextInput source="code__contains" label="Kod" alwaysOn/>
        <TextInput source="codeNickname__contains" label="Takma Ad"/>
    </Filter>
);

export const AccidentCodeList: FC<ListProps> = (props) => (
    <List {...props} filters={<AccidentCodeFilter/>}>
        <Datagrid>
            <TextField source="code" label="Kod"  />
            <TextField source="codeNickname" label="Kod Takma Adı" />
            <TextField source="description" label="Açıklama" />
            <EditButton/>
        </Datagrid>
    </List>
);