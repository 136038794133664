import React from "react";
import {Edit, SimpleForm, TextInput, required, EditProps} from "react-admin";

export const InsuranceEdit:React.FC<EditProps> = (props) => (
    <Edit {...props}>
        <SimpleForm undoable={false}>
            <TextInput source="name" label="Sigorta Şirketi" validate={required()} />
            <TextInput source="code" label="Şirket Kodu" validate={required()}  />
        </SimpleForm>
    </Edit>
);