import TextField from '@material-ui/core/TextField';
import { useInput } from 'react-admin';
import InputMask from "react-input-mask";

export const PhoneInput = (props) => {
    
    const {
        input: { name, onChange, onFocus, onBlur , value ,...rest },
        meta: { touched, error },
        isRequired
    } = useInput(props);

    return (

        <InputMask
            mask="(999) 999 99 99"
            value={value}
            disabled={false}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            maskChar=" "
        >
            {() => <TextField
                name={name}
                label={props.label}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                error={!!(touched && error)}
                helperText={touched && error}
                required={isRequired}
                variant="filled"
                {...rest}
            />}
        </InputMask>
    );
    
}