import {AccidentCodeList} from "./AccidentCodeList";
import {AccidentCodeCreate} from "./AccidentCodeCreate";
import {AccidentCodeEdit} from "./AccidentCodeEdit";

const resources = {
    list:AccidentCodeList,
    create:AccidentCodeCreate,
    edit: AccidentCodeEdit
}

export default resources;