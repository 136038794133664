import React from 'react';
import {Admin, Resource} from "react-admin";
import {UserList} from "./user/UserList";
import {UserEdit} from "./user/UserEdit";
import {UserCreate} from "./user/UserCreate";
import {AccidentUserSearch} from "./accident/AccidentUserSearch";
import accidents from "./accident";
import insurances from "./insurance";
import filetypes from "./file-type";
import accidentcodes from "./accident-code";
import {ankamProTheme} from "./layout/theme";
import {ankamProDataProvider} from "./providers/dataprovider";
import {AnkamProLayout} from "./layout/AnkamProLayout";
import {ankamProAuthProvider} from "./providers/authProvider";
import {ApplicationRoles, apiUrl} from "./app-constants";
import AnkamProLoginPage from './user/AnkamProLogin';
import { SessionHistoryList } from './user/SessionHistoryList';
import {AccidentWaitingCoverList} from './accident/AccidentWaitingCoverList';
import { Route } from 'react-router-dom';
import Dashboard from "./dashboard/Dashboard";
import { AccidentExpertList2 } from './accident/AccidentExpertList2';


const App = () => {
    
    // @ts-ignore
    return (
        <Admin title="App Pro"
               theme={ankamProTheme}
               layout={AnkamProLayout}
               authProvider={ankamProAuthProvider}
               dataProvider={ankamProDataProvider(apiUrl)}
               loginPage={AnkamProLoginPage}
               dashboard={Dashboard}
               customRoutes={[
                   <Route path="/accidents-waiting-cover" component={AccidentWaitingCoverList} />,
                   <Route path="/accidents-waiting-expert" component={AccidentExpertList2} />
               ]}
        >
            
            {permissions => [
                <Resource name="accidents" {...accidents} />,
                <Resource name="accident-histories" />,
                <Resource name="files"/>,
                <Resource name="insurances" {...insurances}  />,
                <Resource name="accident-user" list={AccidentUserSearch}  />,
                <Resource name="descriptions"  />,
                <Resource name="file-types" {...filetypes}  />,
                <Resource name="accident-codes" {...accidentcodes}  />,
                <Resource name="users" list={UserList} create={UserCreate} edit={UserEdit}/>,
                <Resource name="proxies" />,
                (permissions.includes(ApplicationRoles.Admin)  || permissions.includes(ApplicationRoles.SuperAdmin)) && (
                    <Resource name="roles" />
                ),
                permissions.includes(ApplicationRoles.SuperAdmin) && (
                    <Resource name="sessions" list={SessionHistoryList} />
                )
            ]}
        </Admin>
    );
}

export default App;

