import React from "react";
import {CreateProps, Create, SimpleForm, TextInput, required} from "react-admin";

export const InsuranceCreate:React.FC<CreateProps> = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" label="Sigorta Şirketi" validate={required()} />
            <TextInput source="code" label="Şirket Kodu" validate={required()}  />
        </SimpleForm>
    </Create>
);