import {Edit, SimpleForm, TextInput, required, EditProps} from "react-admin";
import {FC} from "react";

export const AccidentCodeEdit:FC<EditProps> = (props) => (
    <Edit {...props}>
        <SimpleForm undoable={false}>
            <TextInput source="code" label="Kod" validate={required()} />
            <TextInput source="codeNickname" label="Kod Takma Adı" />
            <TextInput source="description" label="Açıklama" />
        </SimpleForm>
    </Edit>
);