import {FC, useState} from "react";
import {
    ShowProps,
    Show,
    SimpleShowLayout,
    TabbedShowLayout,
    Tab,
    TextField,
    SelectField,
    DateField,
    ReferenceManyField, Datagrid, TopToolbar,
    EditButton,
    ListButton,
    RefreshButton,
    Create,
    SimpleForm,
    FieldProps, usePermissions, useDataProvider, Loading, useNotify, ReferenceField, RichTextField,
    ResourceContextProvider, useRefresh, NumberField, TabbedShowLayoutTabs, useShowController,
} from "react-admin";
// @ts-ignore
import {Link} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableCell,
    Paper,
    IconButton,
    Card, CardContent
} from "@material-ui/core";
// @ts-ignore
import {BoxedShowLayout, RaBox, ShowSplitter} from "ra-compact-ui";
import {ApplicationRoles, apiUrl} from "../app-constants";
import LaunchIcon from '@material-ui/icons/Launch';
import RichTextInput from "ra-input-rich-text";
import {ExpertApprovalDialog} from "./ExpertApprovalDialog";
import {AccidentCodeUpdateDialog} from "./AccidentCodeUpdateDialog";
import { DocumentAddDialog } from "./DocumentAddDialog";
import {ExpertEvaluationDialog} from "./ExpertEvaluationDialog";
import {HasNoProxy, ProxyShowModel} from "../proxies/ProxyShowModel";
import {AccidentClone} from "./AccidentClone";

const useStyles = makeStyles(theme => ({
    text_h6: theme.typography.h6,
    root: {},
    table: {
        minWidth: 650,
    },
    center_svg: {
        display: "inline-block",
        verticalAlign: "middle"
    },
    expert_evaluation: {
        midWidth: '400px'
    },
    description: {
        minWidth: '40%',
        maxWidth: '40%'
    }
}));


// @ts-ignore
const AccidentShowTitle = ({record}) => {
    return <span> {record ? `"${record.notificationNumber} - ${record.fullName}"` : ''}</span>;
};

export const ShowDocument: FC<FieldProps> = ({record}) => {
    const token = localStorage.getItem("token");

    return (
        record ? (
                <IconButton color="primary" aria-label="Göster"
                            href={`${apiUrl}/files/download/${record.id}?token=${token}`}
                            target="_blank">
                    <LaunchIcon/>
                </IconButton>
            )
            : null
    );
};

const AccidentShowActions = ({basePath, data, resource}) => {
    const {permissions, loading: permissionLoading} = usePermissions();

    if (permissionLoading) {
        return null;
    }

    return (
        <TopToolbar>
            {
                (permissions.includes(ApplicationRoles.Admin) || permissions.includes(ApplicationRoles.SuperAdmin)) && (
                    <EditButton basePath={basePath} record={data} label="Düzenle"/>
                )
            }
            <ListButton basePath={basePath} label="Kazalar"/>
            <RefreshButton label="Yenile"/>
            <AccidentClone data={data} />
            <AccidentCodeUpdateDialog data={data} />
            <DocumentAddDialog data={data} />
            <ExpertEvaluationDialog data={data} />
            <ExpertApprovalDialog data={data}/>
        </TopToolbar>
    );
};

export const AddDescription: FC = (props) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const classes = useStyles();

    const onSuccess = () => {
        refresh();
        notify(`Açıklama eklendi`, "success");
    }

    return (
        <Box m="0 0 1em 1em" className={classes.description}>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Açıklama Ekle
                    </Typography>
                    <ResourceContextProvider value="descriptions">
                        <Create basePath="descriptions" resource="descriptions" onSuccess={onSuccess}>
                            {/*@ts-ignore*/}
                            <SimpleForm redirect={false} initialValues={{accidentId: props.accidentId}}>
                                <RichTextInput source="text"/>
                            </SimpleForm>
                        </Create>
                    </ResourceContextProvider>
                </CardContent>
            </Card>
        </Box>
    )
}

export const AccidentShow: FC<ShowProps> = (props) => {
    const classes = useStyles();
    const {record} = useShowController(props);
    // @ts-ignore
    return (
        // @ts-ignore
        <Show {...props} title="Kaza" actions={<AccidentShowActions/>} className={classes.root} aside={<AddDescription accidentId={props.id}/>}>
             {/*@ts-ignore*/}
            <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" {...props} />}>
                <Tab label="Müşteri Bilgileri">
                    <BoxedShowLayout>
                        <RaBox display="flex">
                            <RaBox display="flex" flexWrap="wrap" flexGrow={4}>
                                <RaBox flex="0 0 100%" display="flex" justifyContent="space-around">
                                    <Typography variant="h6">
                                        <TextField source="fullName" className={classes.text_h6} label=""/>
                                    </Typography>
                                    <Typography variant="h6">
                                        <TextField source="notificationNumber" className={classes.text_h6} label=""/>
                                    </Typography>
                                </RaBox>
                                <Separator/>
                                <RaBox flex="0 0 100%" display="flex" justifyContent="center">
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center"> Kaza Tipi </TableCell>
                                                    <TableCell align="center">
                                                        <SelectField source="accidentType" label=""
                                                                     className={classes.text_h6}
                                                                     choices={[{id: 1, name: "Kusur"}, {
                                                                         id: 2,
                                                                         name: "Değer Kaybı"
                                                                     }]}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Dosya Kodu </TableCell>
                                                    <TableCell align="center">
                                                        <ReferenceField reference="accident-codes" source="accidentCodeId" link={false}>
                                                            <TextField source="code" className={classes.text_h6}
                                                                       label=""/>
                                                        </ReferenceField>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Hasar Dosya Numarası </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="damageFileNumber" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Kaza Tarihi </TableCell>
                                                    <TableCell align="center">
                                                        <DateField source="accidentDate" label="" className={classes.text_h6}/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> T.C. Numarası </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="tcNumber" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Vergi Numarası </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="taxNumber" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Sürücü İsmi </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="driverFullName" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Sürücü Telefon No </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="driverPhoneNumber" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center">Araç Plakası </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="licencePlate"
                                                                   className={classes.text_h6} label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Araç Marka/Model/Paket </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="carBrandModelPackage" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Araç Model Yılı </TableCell>
                                                    <TableCell align="center">
                                                        <NumberField source="CarModelYear" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Trafik Sigorta Firması </TableCell>
                                                    <TableCell align="center">
                                                        <ReferenceField reference="insurances" source="insuranceId" link={false} >
                                                            <TextField source="name" className={classes.text_h6}
                                                                       label=""/>
                                                        </ReferenceField>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Trafik Sigorta Poliçe No </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="trafficInsuranceNumber" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Trafik Sigorta Bitiş Tarihi </TableCell>
                                                    <TableCell align="center">
                                                        <DateField source="trafficInsuranceExperyDate" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Kasko Firması </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="insuranceCompany" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Kasko Poliçe No </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="insuranceNumber" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Vekalet Durumu </TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            record?.proxyId ? <ProxyShowModel proxyId={record.proxyId} /> : <HasNoProxy />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </RaBox>
                            </RaBox>
                        </RaBox>
                    </BoxedShowLayout>
                </Tab>
                <Tab label="Karşı Taraf Bilgileri">
                    <BoxedShowLayout>
                        <RaBox display="flex">
                            <RaBox display="flex" flexWrap="wrap" flexGrow={4}>
                                <RaBox flex="0 0 100%" display="flex" justifyContent="space-around">
                                    <Typography variant="h6">
                                        <TextField source="opponent.fullName" className={classes.text_h6} label=""/>
                                    </Typography>
                                    <Typography variant="h6">
                                        <TextField source="opponent.tcNumber" className={classes.text_h6} label=""/>
                                    </Typography>
                                </RaBox>
                                <Separator/>
                                <RaBox flex="0 0 100%" display="flex" justifyContent="center">
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center"> Sürücü İsmi </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="opponent.driverFullName"
                                                                   className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Sürücü TC No </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="opponent.driverTCNumber"
                                                                   className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Telefon </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="opponent.phone" label=""
                                                                   className={classes.text_h6}/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Plaka </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="opponent.licencePlate"
                                                                   className={classes.text_h6} label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Araç Marka/Model/Paket </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="opponent.carBrandModelPackage" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Araç Model Yılı </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="opponent.carModelYear" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Trafik Sigortası </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="opponent.trafficInsuranceCompany" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Trafik Sigorta Poliçe No </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="opponent.trafficInsuranceNumber" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Trafik Sigorta Bitiş Tarihi </TableCell>
                                                    <TableCell align="center">
                                                        <DateField source="opponent.TrafficInsuranceExperyDate" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Kasko Firması </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="opponent.insuranceCompany" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"> Kasko Poliçe No </TableCell>
                                                    <TableCell align="center">
                                                        <TextField source="opponent.insuranceNumber" className={classes.text_h6}
                                                                   label=""/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </RaBox>
                            </RaBox>
                        </RaBox>
                    </BoxedShowLayout>
                </Tab>
                <Tab label="Açıklamalar">
                    <Card>
                        <CardContent>
                            <ReferenceManyField reference="descriptions" target="accidentId" fullWidth>
                                <Datagrid>
                                    <DateField source="createdDate" label="Oluşturulma Tarihi" showTime={true}/>
                                    <ReferenceField reference="users" source="createdUserId" label="Kişi"
                                                    link={false}>
                                        <TextField source="fullName" label="Kişi"/>
                                    </ReferenceField>
                                    <RichTextField source="text" label="Açıklama"/>
                                </Datagrid>
                            </ReferenceManyField>
                        </CardContent>
                    </Card>
                </Tab>
                <Tab label="Dosyalar">
                    <SimpleShowLayout>
                        <ReferenceManyField reference="files" target="accidentId" label="" fullWidth>
                            <Datagrid>
                                <ReferenceField label="Dosya Tipi" reference="file-types" source="appFileTypeId"
                                                link={false}>
                                    <TextField source="typeName"/>
                                </ReferenceField>
                                <TextField source="name" label="Dosya İsmi"/>
                                <DateField source="createdDate" label="Eklenme Tarihi" showTime={true}/>
                                <ShowDocument/>
                            </Datagrid>
                        </ReferenceManyField>
                    </SimpleShowLayout>
                </Tab>
                <Tab label="Geçmiş">
                    <SimpleShowLayout>
                        <ReferenceManyField reference="accident-histories" target="accidentId" label="Statü Geçmişi"
                                            fullWidth>
                            <Datagrid>
                                <ReferenceField label="Kişi" reference="users" source="createdUserId"
                                                link={false}>
                                    <TextField source="fullName"/>
                                </ReferenceField>
                                <TextField source="accidentStatusDescription" label="Statü"/>
                                <DateField source="createdDate" label="Tarih" showTime={true}/>
                            </Datagrid>
                        </ReferenceManyField>
                    </SimpleShowLayout>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}

const Separator = () => <Box pt="5em"/>;
