import * as React from 'react';
import { Box, Card, CardActions, Button, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CodeIcon from '@material-ui/icons/Code';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        background:'#fff',
        padding: 20,
        marginTop: theme.spacing(2),
        marginBottom: '1em',
    },
    actions: {
        [theme.breakpoints.down('md')]: {
            padding: 0,
            flexWrap: 'wrap',
            '& a': {
                marginTop: '1em',
                marginLeft: '0!important',
                marginRight: '1em',
            },
        },
    },
}));

const Welcome = () => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Box display="flex">
                <Box flex="1">
                    <Typography variant="h5" component="h2" gutterBottom>
                       AnkamPro'ya Hoşgeldiniz.
                    </Typography>
                    <Box maxWidth="40em">
                        <Typography variant="body1" component="p" gutterBottom>
                            {/*Lorem Ipsum, dizgi ve baskı endüstrisinde kullanılan mıgır metinlerdir.*/}
                        </Typography>
                    </Box>
                    {/*<CardActions className={classes.actions}>*/}
                    {/*    <Button*/}
                    {/*        variant="contained"*/}
                    {/*        href="https://marmelab.com/react-admin"*/}
                    {/*        startIcon={<HomeIcon />}*/}
                    {/*    >*/}
                    {/*        {translate('pos.dashboard.welcome.ra_button')}*/}
                    {/*    </Button>*/}
                    {/*    <Button*/}
                    {/*        variant="contained"*/}
                    {/*        href="https://github.com/marmelab/react-admin/tree/master/examples/demo"*/}
                    {/*        startIcon={<CodeIcon />}*/}
                    {/*    >*/}
                    {/*        {translate('pos.dashboard.welcome.demo_button')}*/}
                    {/*    </Button>*/}
                    {/*</CardActions>*/}
                </Box>
            </Box>
        </Card>
    );
};

export default Welcome;