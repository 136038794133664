import {FC} from "react";
import {
    Datagrid,
    DateField,
    EditButton,
    List,
    ReferenceField,
    ShowButton,
    TextField, 
    usePermissions,
    ResourceContextProvider
} from "react-admin";
import {AccidentStatus, ApplicationRoles} from "../app-constants";
import {AccidentTypeField} from "./AccidentList";
import { excelExporter } from "./ExcelExporter";


export const AccidentWaitingCoverList:FC = () => {
    const {permissions, loading:permissionLoading} = usePermissions();
    if (permissionLoading) {
        return null;
    }
    const exportAcl = ():any => (
        (permissions.includes(ApplicationRoles.Admin) || permissions.includes(ApplicationRoles.SuperAdmin))
            ? excelExporter : false
    );
    return (
        <ResourceContextProvider value="accidents">
            <List syncWithLocation
                  basePath="/accidents"
                  hasCreate={false} 
                  exporter={exportAcl()} 
                  filter={{accidentStatus__eq: AccidentStatus.WaitingForCover}} 
                  sort={{field: 'createdDate', order: 'DESC'}}>
                <Datagrid>
                    <TextField source="notificationNumber" label="İhbar No"/>
                    <ReferenceField source="insuranceId" link={false} reference="insurances" label="Sigorta Firması">
                        <TextField source="name"/>
                    </ReferenceField>

                    <TextField source="fullName" label="İsim"/>
                    <TextField source="tcNumber" label="Tc No"/>
                    <TextField source="licencePlate" label="Plaka"/>
                    {/*<BooleanField source="isAuthorized" label="Vekalet Durumu"/>*/}
                    {/*{*/}
                    {/*    (permissions.includes(ApplicationRoles.Admin) || permissions.includes(ApplicationRoles.SuperAdmin)) && (*/}
                    {/*        <ReferenceField link={false} label="Oluşturan" reference="users" source="createdUserId">*/}
                    {/*            <TextField source="fullName"/>*/}
                    {/*        </ReferenceField>)*/}
                    {/*}*/}
                    <DateField source="createdDate" label="Oluşturulma Tarihi" showTime/>

                    <AccidentTypeField label="Tip"/>
                    <TextField source="accidentStatusDescription" label="Statü"/>
                    <ShowButton basePath="/accidents"/>
                    {
                        (permissions.includes(ApplicationRoles.Admin) || permissions.includes(ApplicationRoles.SuperAdmin) || permissions.includes(ApplicationRoles.Technical)) && (
                            <EditButton basePath="/accidents"/>
                        )
                    }
                </Datagrid>
            </List>
        </ResourceContextProvider>
    );
}

